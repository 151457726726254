
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination, addMoneyMask, formatCPFToLGPD, formatDate } from "@/libs/utils";

const VCashierMovementList = defineComponent({
  name: "VCashierMovementList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de movimentações | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilter();

    const movements = computed(() => store.state.cashier.movement.list);
    const loading = reactive({ list: false, pdf: false });

    const breadcrumb = [
      {
        label: "Financeiro",
        icon: "fas fa-wallet mr-1",
        to: { name: "cashier-historic-list", query: route.query },
      },
      {
        label: "Movimentações",
        icon: "fas fa-landmark mr-1",
        to: { name: "cashier-movement-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "cashier.movement",
      setPage: "setCashierMovementPaginationPage",
      updateList: getCashierMovements,
    });

    async function getCashierMovementPDF() {
      loading.pdf = true;
      const response = await store.dispatch("getCashierMovementPDF");
      loading.pdf = false;

      if ((response?.status || 500) >= 300) return;
      window.open(URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" })));
    }

    async function getCashierMovements() {
      loading.list = true;
      await store.dispatch("getCashierMovements");
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "cashier-movement-filter", query: route.query });
    else getCashierMovements();

    return {
      ...{ movements, pagination, loading, breadcrumb, hasFilters },
      ...{ handleUpdateCurrentPage, getCashierMovements, getCashierMovementPDF },
      ...{ addMoneyMask, formatDate, formatCPFToLGPD },
    };
  },
});

export default VCashierMovementList;
